/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {
  background: url('../../assets/background/overlay_why.png'), var(--deep);
  background-repeat: no-repeat;
  background-size: 100% 50%;
  color: var(--white);

  .imgDesk {
    display: none;
  }


  @media screen and (min-width: 1100px) {
    display: flex;

    .imgDesk {
      display: flex;
      align-self: stretch;
      max-width: 50%;
      object-fit: contain;
      padding: 4rem 5%;
    }
  }
  
  .wrapper {
    padding: 3rem 1rem;

    @media screen and (min-width: 768px) {
      padding: 5rem 5%;
    }

    @media screen and (min-width: 1100px) {
      padding: 5rem 0 5rem 5%;
    }
  }

  .header {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
      margin: 1rem 0;

      @media screen and (min-width: 768px) {
        span {
          display: block;
          color: var(--light);
        }
      }
    }

    .subTitle {
      text-transform: uppercase;
      position: relative;
      color: var(--secondary);

      &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: -3rem;
        width: 35px;
        height: 4px;
        background: var(--white);
        margin: 0.5rem auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10%;
        right: -3rem;
        width: 35px;
        height: 4px;
        background: var(--white);
        margin: 0.5rem auto;
      }
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }
    }

    @media screen and (min-width: 1100px) {
      align-items: flex-start;

      .title {
        text-align: left;
      }

      .subTitle {
        left: 10%;
      }
    }
  }

  .content {
    padding: 2rem 0 0;
    @extend .flexAlignCol;

    .textContent {
      @extend .flexCol;
      gap: 2.5rem;

      .text {
        text-align: justify;
        padding: 0 0.5rem;
        line-height: 1.6;
      }

      @media screen and (min-width: 768px) {
        .img {
          display: none;
        }

        .text {
          text-align: center;
          max-width: 80%;
          margin: 0 auto;
        }
      }

      @media screen and (min-width: 1100px) {
        .text {
          text-align: justify;
          max-width: 100%;
          margin: 0;
          padding: 0;
        }
      }
    }

    .perksDiv {
      @extend .flexCol;
      gap: 3rem;

      .perk {
        position: relative;
        @extend .flexAlignCol;
        gap: 0.5rem;
        margin: 0 auto;

        .div {
          text-align: center;

          .heading {
            color: var(--secondary);
            margin-bottom: 1rem;
            font-size: 1.25rem;
          }

          .text {
            text-align: center;
            padding: 0 0.5rem;
            line-height: 1.6;
          }
        }
      }

      .link {
        text-decoration: underline;
        color: var(--light);
        font-weight: 600;
        padding: 0 0.5rem;
        display: block;
        text-align: center;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--white);
        }

        @media screen and (min-width: 1100px) {
          text-align: left;
          padding: 0;
        }
      }

      @media screen and (min-width: 768px) {
        width: 100%;
        display: grid;
        justify-content: center;

        .perk {
          .div {
            .text {
              max-width: 80%;
              margin: 0 auto;
            }
          }
        }
      }

      @media screen and (min-width: 1100px) {
        .perk {
          .div {
            text-align: left;

            .text {
              max-width: 100%;
              margin: 0;
              text-align: left;
              padding: 0;
            }
          }
        }
      }
    }
  }
}
