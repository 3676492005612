.button {
  border: none;
  background: var(--menu);
  border-radius: 10px;
  font-size: 16px;
  color: var(--white);
  padding: 1rem;
  font-weight: 500;
  width: fit-content;
  transition: all 0.2s ease;

  &:hover {
    background-color: var(--deep);
    transform: scale(1.03);
  }

  &:active {
    transform: scale(0.9);
  }

  @media screen and (min-width: 768px) {
    font-size: 18px;
    padding: 1rem 1.35rem;
    min-width: fit-content;
  }
}