/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {
  
  .wrapper {
    padding: 3rem 1rem 1rem;

    @media screen and (min-width: 768px) {
      padding: 5rem 5%;
    }
  }

  .header {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
      margin: 1rem 0;

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        max-width: unset;
        color: var(--deep);
        span {
          display: block;
        }
      }
    }

    .subTitle {
      text-transform: uppercase;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: -3rem;
        width: 35px;
        height: 4px;
        background: var(--deep);
        margin: 0.5rem auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10%;
        right: -3rem;
        width: 35px;
        height: 4px;
        background: var(--deep);
        margin: 0.5rem auto;
      }
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }
    }
  }

  .content {
    padding: 1rem 0 0;
    @extend .flexAlignCol;

    .textContent {
      @extend .flexCol;
      gap: 1rem;

      .text {
        text-align: justify;
        padding: 0 0.5rem;
      }

      @media screen and (min-width: 768px) {
        .text {
          padding: 0;
          max-width: 650px;
          line-height: 1.5;
          margin: 0 auto;
          text-align: center;
        }
      }
    }

    .imgDesk {
      display: none;
    }

    .perksDiv {
      padding: 2rem 0;
      @extend .flexCol;
      gap: 2rem;

      .perk {
        position: relative;
        @extend .flexAlignCol;
        background-color: var(--light);
        border-radius: 20px 20px 0 0;
        gap: 0.5rem;
        width: 340px;
        height: 380px;
        padding: 2rem 1.5rem 0;
        margin: 0 auto;

        .iconDiv {
          position: absolute;
          background: var(--deep);
          @extend .flexAlignCol;
          gap: 1.5rem;
          padding: 1rem 0.5rem;
          top: 30px;
          left: 84%;
          border-radius: 5px;

          .socialLink {
            font-size: 1.5rem;
            color: var(--light);
            transition: all 0.2s ease-in-out;

            &:active {
              color: var(--secondary);
              transform: scale(0.9);
            }

            .icon {
              width: 24px;
              height: 24px;
            }
          }
        }

        .img {
          position: relative;
          bottom: 0;
        }

        .base {
          position: absolute;
          display: flex;
          gap: 1rem;
          align-items: center;
          @extend .justContentCenter;
          background: var(--deep);
          width: 90%;
          height: 70px;
          bottom: 0;
          right: 0;
          border-top-left-radius: 50px;

          .div {
            text-align: center;
  
            .heading {
              color: var(--white);
              font-size: 1rem;
            }
  
            .text {
              color: var(--light);
              font-style: italic;
              font-size: 0.8rem;
              font-weight: 600;
            }
          }
        }
      }

      @media screen and (min-width: 768px) {
        width: 100%;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;

        .perk {
          .div {
            .text {
              text-align: justify;
              margin: 0;
              max-width: 100%;
            }
          }
        }
      }

      @media screen and (min-width: 1100px) {
        max-width: 1280px;
        margin: 0 auto;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
