/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {
  .imgDesk {
    display: none;
  }

  .wrapper {
    padding: 3rem 1rem;

    @media screen and (min-width: 768px) {
      padding: 4rem 5%;
    }
  }

  @media screen and (min-width: 1100px) {
    display: flex;

    .imgDesk {
      display: flex;
      max-width: 50%;
      align-self: stretch;
      object-fit: contain;
      padding: 4rem 5%;
    }
  }

  .header {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
      margin: 1rem 0;

      @media screen and (min-width: 768px) {
        span {
          display: block;
          color: var(--deep);
        }
      }
    }

    .subTitle {
      text-transform: uppercase;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: -3rem;
        width: 35px;
        height: 4px;
        background: var(--deep);
        margin: 0.5rem auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10%;
        right: -3rem;
        width: 35px;
        height: 4px;
        background: var(--deep);
        margin: 0.5rem auto;
      }
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }
    }

    @media screen and (min-width: 1100px) {
      align-items: flex-start;

      .title {
        text-align: left;
      }

      .subTitle {
        left: 10%;
      }
    }
  }

  .content {
    padding: 1rem 0 0;
    @extend .flexAlignCol;

    .textContent {
      @extend .flexCol;
      gap: 1rem;

      .text {
        text-align: justify;
        padding: 0 0.5rem;
      }

      @media screen and (min-width: 768px) {
        .img {
          width: 80%;
          margin: 0 auto;
        }

        @media screen and (min-width: 1100px) {
          .text {
            padding: 0;
          }

          .img {
            display: none;
          }
        }
      }
    }

    .perksDiv {
      padding: 2rem 0;
      @extend .flexCol;
      gap: 2rem;

      .perk {
        position: relative;
        @extend .flexAlignCol;
        border-radius: 10px;
        gap: 0.5rem;
        max-width: 342px;
        min-height: 233px;
        padding: 2rem 1.5rem;
        margin: 0 auto;
        -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow:0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

        .iconDiv {
          position: absolute;
          background: var(--deep);
          width: 45px;
          height: 44px;
          top: 0;
          left: 0;
          border-radius: 5px 5px 41.018px 5px;

          .icon {
            width: 40%;
            margin: 0.7rem;
          }

          .iconB {
            display: none;
          }
        }

        .div {
          text-align: center;

          .heading {
            color: var(--deep);
            margin-bottom: 0.5rem;
            font-size: 1rem;
          }

          .text {
            text-align: center;
          }
        }
      }

      @media screen and (min-width: 768px) {
        width: 100%;
        align-items: flex-start;
        gap: 1.5rem;

        .perk {
          -webkit-box-shadow: unset;
          -moz-box-shadow: unset;
          box-shadow: unset;
          max-width: 100%;
          min-height: unset;
          border-radius: unset;
          padding: 0;
          align-items: flex-start;
          flex-direction: row;
          gap: 1.25rem;
          margin: 0 auto;

          .iconDiv {
            position: static;
            background: var(--white);
            border: 1px solid var(--deep);
            width: 120px;
            height: 76px;
            align-self: stretch;
            display: flex;
            padding: 1rem;
            align-items: center;
            justify-content: center;

            .icon {
              display: none;
            }
  
            .iconB {
              display: flex;
              width: 32px;
              height: 32px;
            }
          }

          .div {
            text-align: left;
  
            .heading {
              color: var(--deep);
              margin-bottom: 0.5rem;
              font-size: 1.35rem;
            }
  
            .text {
              text-align: justify;
              padding: 0;
              max-width: 80%;

              @media screen and (min-width: 1100px) {
                max-width: 100%;
              }
            }
          }
        }

      }
    }

    @media screen and (min-width: 1100px) {
      flex-direction: column;
    }
  }

  @media screen and (min-width: 960px) {
   
  }
}
