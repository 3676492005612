/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {
  position: relative;
  
  .wrapper {
    padding: 2.5em 1rem 1rem;

    @media screen and (min-width: 768px) {
      padding: 5rem 5% 2rem;
    }
  }

  .header {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
      margin: 1rem 0;

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        max-width: unset;
        color: var(--deep);
        span {
          display: block;
        }
      }
    }

    .subTitle {
      text-transform: uppercase;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: -3rem;
        width: 35px;
        height: 4px;
        background: var(--deep);
        margin: 0.5rem auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10%;
        right: -3rem;
        width: 35px;
        height: 4px;
        background: var(--deep);
        margin: 0.5rem auto;
      }
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }
    }
  }

  .content {
    position: relative;
    padding: 1rem 0 0;
    @extend .flexAlignCol;

    .textContent {
      @extend .flexCol;
      gap: 1rem;

      .text {
        text-align: justify;
        padding: 0 0.5rem;
      }

      @media screen and (min-width: 768px) {
        .text {
          padding: 0;
          max-width: 650px;
          line-height: 1.5;
          margin: 0 auto;
          text-align: center;
        }
      }
    }

    .imgDesk {
      display: none;
    }

    .perksDiv {
      padding: 2rem 0;
      @extend .flexCol;
      gap: 2rem;

      .perk {
        position: relative;
        @extend .flexAlignCol;
        background-color: var(--light);
        border-radius: 20px 20px 0 0;
        gap: 0.5rem;
        width: 340px;
        height: 380px;
        padding: 2rem 1.5rem 0;
        margin: 0 auto;

        .iconDiv {
          position: absolute;
          background: var(--deep);
          @extend .flexAlignCol;
          gap: 1.5rem;
          padding: 1rem 0.5rem;
          top: 30px;
          left: 84%;
          border-radius: 5px;

          .socialLink {
            font-size: 1.5rem;
            color: var(--light);
            transition: all 0.2s ease-in-out;

            &:active {
              color: var(--secondary);
              transform: scale(0.9);
            }

            .icon {
              width: 24px;
              height: 24px;
            }
          }
        }

        .base {
          position: absolute;
          display: flex;
          gap: 1rem;
          align-items: center;
          background: var(--deep);
          width: 90%;
          height: 70px;
          bottom: 0;
          right: 0;
          border-top-left-radius: 50px;

          .btn {
            position: relative;
            border: none;
            width: 50px;
            height: 50px;
            margin-left: 0.55rem;
            background: var(--light);
            border-top-left-radius: 35px;

            .button {
              font-size: 1.5rem;
              margin: 0.35rem 0 0 0.35rem;
            }
          }

          .div {
            text-align: center;
  
            .heading {
              color: var(--white);
              font-size: 1rem;
            }
  
            .text {
              color: var(--light);
              font-style: italic;
              max-width: 100%;
              font-size: 0.8rem;
              font-weight: 600;
            }
          }
        }
      }

      @media screen and (min-width: 768px) {
        width: 100%;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
        gap:  2rem;

        @media screen and (min-width: 1100px) {
          max-width: 1280px;
          margin: 0 auto;
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}

// Pop up card
.memberWrapper {
  position: fixed;
  background-color: rgba(2, 1, 101, 0.8);
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  top: 0; 
  left: 0;
  overflow-y: auto;

  .coiner {
    position: absolute;
    top: 15vh;
    left: 0;
    right: 0;
    width: 90%;
    height: 80vh;
    margin: 0 auto;
    background: var(--light);
    font-size: 15px;
    color: var(--black);
    text-align: justify;
    padding: 1rem;
    border-radius: 40px 40px 0 0;
    overflow-y: auto;
    translate: (50%, 50%);

    .member {
      position: relative;
      padding-top: 1rem;

      .text {
        padding: 0.5rem 0;
      }
      .name {
        margin-top: 2rem;
        color: var(--deep);
      }

      .role {
        font-style: italic;
      }

      .img {
        position: absolute;
        width: 70%;
        top: 75%;
        right: -60px;
      }

      .iconDiv {
        width: 50px;
        height: 50px;
        background-color: var(--deep);
        border: none;
        margin-top: 2rem;
        border-radius: 20px 0 0 20px;
        transition: all 0.2s ease-in-out;

        &:active {
          transform: scale(0.9);
        }

        .icon {
          width: 50%;
          height: 50%;
          margin: 0.25rem;
          color: var(--light);
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    background-color: rgba(212, 232, 247, 0.8);
    backdrop-filter: blur(1px);

    .coiner {
      background: var(--deep);
      border-radius: 0;
      width: 80vw;
      height: 80vh;
      z-index: 9999;
      color: white;
      padding: 2rem 2rem 0 0;

      .member {
        position: relative;
        padding-top: 1rem;
        display: flex;
        gap: 1rem;
        flex-direction: row-reverse;
  
        .name {
          margin-top: 2rem;
          color: var(--light);
        }
  
        .role {
          font-style: italic;
        }

        .img {
          position: static;
          height: 100vh;
          margin-left: -130px;
        }

        .iconDiv {
          width: 60px;
          height: 60px;
          background-color: var(--light);
          margin-bottom: 2rem;

          .icon {
            color: var(--deep);
          }
        }
      }
    }
  }
}