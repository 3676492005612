/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {
  background-color: var(--deep);
  color: var(--white);
  
  .wrapper {
    padding: 3rem 1rem;

    @media screen and (min-width: 768px) {
      padding: 4rem 5% 2rem;
    }
  }

  @media screen and (min-width: 768px) {
    background: url('../../assets/background/drive_bg.svg') no-repeat, var(--deep);
    background-position: top left;
    background-size: 20%;
  }

  .header {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      line-height: 1.4;
      text-transform: capitalize;
      text-align: center;
      margin: 1rem 0;

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        max-width: unset;
        span {
          display: block;
          color: var(--secondary);
        }
      }
    }

    .subTitle {
      text-transform: uppercase;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: -3rem;
        width: 35px;
        height: 4px;
        background: var(--secondary);
        margin: 0.5rem auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10%;
        right: -3rem;
        width: 35px;
        height: 4px;
        background: var(--secondary);
        margin: 0.5rem auto;
      }
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }
    }
  }

  .content {
    padding: 1rem 0 0;
    @extend .flexAlignCol;

    .textContent {
      @extend .flexCol;
      gap: 1rem;

      .text {
        text-align: justify;
        padding: 0 0.5rem;
        line-height: 1.6;

        span {
          font-weight: 700;
          color: var(--light);
        }

        @media screen and (min-width: 768px) {
          padding: 0 5%;
          text-align: justify;
          max-width: 1280px;
          margin: 0 auto;
        }
      }
    }

    /* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

/***********   */
.stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: var(--white);
  padding: 2rem 1rem;

  .stat {
    @extend .flexAlignCol;
    padding: 1rem;
    color: var(--black);

    .icon {
      width: 32px;
    }

    .bullets {
      @extend .stat;
      padding: 0.6rem 0 0;
    }

    .number {
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--light);
    }

    .text {
      text-align: center;
      margin-top: 0.5rem;
      color: var(--secondary);
    }
  }

  @media screen and (min-width: 1100px) {
    padding: 3rem 5%;
    gap: 1rem;

    .stat {
      flex-direction: row;
      gap: 1rem;
      padding: 0;

      .bullets {
        flex-direction: row;

        .text {
          text-align: left;
          max-width: 20%;
        }
      }

      .icon {
        width: 60px;
      }
      .number {
        font-size: 2.35rem;
      }
    }
  }
}

    .perksDiv {
      padding: 2rem 0;
      @extend .flexCol;
      gap: 2rem;

      .perk {
        position: relative;
        @extend .flexAlignCol;
        border-radius: 10px;
        gap: 0.5rem;
        // width: 70%;
        max-width: 342px;
        min-height: 233px;
        padding: 2rem 1.5rem;
        margin: 0 auto;
        background: var(--light);
        -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow:0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

        .iconDiv {
          position: absolute;
          border-right: 1px solid var(--deep);
          border-bottom: 1px solid var(--deep);
          width: 45px;
          height: 44px;
          top: 0;
          left: 0;
          border-bottom-right-radius: 40.018px;

          .icon {
            width: 40%;
            margin: 0.7rem;
          }
        }

        .div {
          text-align: center;

          .heading {
            color: var(--deep);
            margin-bottom: 0.5rem;
            font-size: 1rem;
          }

          .text {
            text-align: center;
            color: var(--deep);

            @media screen and (min-width: 768px) {
              text-align: center;
            }
          }
        }
      }

      @media screen and (min-width: 768px) {
        width: 100%;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);
        column-gap:  0.5rem;

        .perk {
          padding: 2rem 1rem;
          max-width: 350px;
          min-height: 250px;

          .iconDiv {
            width: 55px;
            height: 54px;
            border-bottom-right-radius: 45.018px;

            .icon {
              width: 50%;
            }
          }
        }
      }

      @media screen and (min-width: 1100px) {
        max-width: 1280px;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 1rem;
        margin: 0 auto;

        .perk {
          padding: 2rem 1rem;
          max-width: 350px;
          min-height: 250px;

          .iconDiv {
            width: 65px;
            height: 64px;
            border-bottom-right-radius: 45.018px;

            .icon {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
