.flex {
  display: flex;
}

.w100 {
  width: 100%;
}

.flexCol {
  @extend .flex;
  flex-direction: column;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.container {

  .wrapper {
    padding: 3rem 1rem;
    @extend .flexCol;
    gap: 4rem;

    @media screen and (min-width: 960px) {
      .divider {
        display: flex;
        justify-content: space-between;
        .image {
          width: 40%;
        }
      }
    }

    .image {
      display: none;
      @media screen and (min-width: 1100px) {
        display: block;
        max-width: 60%;
        margin: 1rem auto;
        object-fit: contain;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 4rem 5%;
      max-width: 1280px;
      margin: 0 auto;
    }
  }

  .heading {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      color: var(--black);
      margin-bottom: 1rem;
      text-transform: capitalize;
      text-align: center;
      width: 70%;

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        line-height: 1.4;
        span {
          display: block;
          color: var(--deep);
        }
      }
    }

    .form {
      @extend .flexCol;
      gap: 1rem;
      width: 85%;
      margin: 2rem auto;

      @media screen and (min-width: 768px) {
        width: 60%;

        @media screen and (min-width: 960px) {
          width: 70%;
        }
      }

      .inputDiv {
        @extend .flexCol;
        // @extend .justContentSpaceBtw;
        gap: 1rem;
      }

      .select,
      .input {
        padding: 0.35rem 1rem;
        color: var(--black);
        font-size: 0.85rem;
        width: 100%;
        height: 45px;
        border-radius: 5px;
        border: 1.049px solid rgba(2, 1, 101, 0.47);
        background: rgba(212, 232, 247, 0.13);
        outline: none;
        transition: all 0.3s ease-in-out;

        &:focus {
          border-color: var(--deep);
        }
      }

      .textarea {
        padding: 0.5rem 1rem;
        border-radius: 5px;
        font-size: 0.85rem;
        border: 1.049px solid rgba(2, 1, 101, 0.47);
        background: rgba(212, 232, 247, 0.13);
        color: var(--black);
        outline: none;
        line-height: 1.7;
        min-height: 150px;
        transition: all 0.3s ease-in-out;
        resize: none;

        &:focus {
          border-color: var(--deep);
        }
      }

      .button {
        width: fit-content;
        padding: 1rem 2rem;
        align-self: center;
        border: none;
        border-radius: 5px;
        margin-top: 1rem;
        color: var(--white);
        background: var(--deep);
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--deep);
          background: var(--light);
        }
      }
    }
  }

  .content {
    display: grid;
    gap: 1rem;

    .contact {
      @extend .flexAlignCol;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      gap: 0.5rem;
      max-width: 70%;
      margin: 0 auto;
      padding: 1.5rem 1rem;
      @extend .justContentCenter;
      border-radius: 10px;

      .icon {
        font-size: 2rem;
        color: var(--deep);
        margin-bottom: 0.5rem;
      }
  
      .title {
        margin-bottom: 0.25rem;
        font-size: 0.8rem;
        font-weight: 700;
        color: var(--black);
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: -0.2rem;
          left: 50%;
          transform: translateX(-50%);
          width: 30px;
          height: 2px;
          background: var(--deep);
        }
      }

      .text {
        font-size: 15px;
        color: black;
        text-align: center;
        max-width: 90%;
      }

      a,
      address {
        color: var(--deep);
        text-decoration: none;
        font-size: 0.75rem;
        font-weight: 700;
        margin-top: 0.5rem;
        text-align: center;
        display: block;
        font-style: normal;
        width: 100%;
      }

      address {
        line-height: 1.5;
      }

      @media screen and (min-width: 768px) {
        max-width: 100%;

        .title {
          font-size: 1rem;
        }

        .text {
          font-size: 15px;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0;
    margin-top: 5rem;

    .header {
      padding: 3rem 5% 0;
    }

    .content {
      padding: 1rem 5% 5%;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.containee {
  position: relative;
  @extend .flexAlignCol;
  @extend .justContentCenter;
  text-align: center;
  @extend .w100;
  padding: 2rem 1rem 3rem;
  margin-top: 8rem;

  .content {
    @extend .flexAlignCol;
    gap: 1rem;
    margin-top: 2vh;

    .video {
      width: 50%;
      margin: 2rem auto;
      display: block;

      @media screen and (min-width: 600px) {
        width: 30%;  
      }
    }
  }

  .btn {
    display: flex;
    padding: 1rem 0 0;
    font-size: 1rem;
    color: var(--secondary);
    border: none;
    cursor: pointer;
    border-bottom: 1px solid var(--deep);
    margin: 3rem 0 1rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: var(--deep);
    }

    &:active {
      outline: none;
      transform: scale3d(1.05, 1.05, 1.05);
    } 
  }
}