/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {

  li {
    list-style: unset;
  }

  .wrapper {
    padding: 3rem 1rem;
    .contentDiv {
      @extend .flexCol;
      gap: 3rem;
      padding-bottom: 3rem;

      .eventDetails {
        border-bottom: 1px solid rgba(2, 1, 101, 0.15);
        padding-bottom: 3rem;
        .heading {
          position: relative;
          @extend .flex;
          align-items: center;
          gap: 1rem;
          min-height: 70px;

          .date {
            position: absolute;
            background: var(--deep);
            // gap: 0.15rem;
            @extend .flexAlignCol;
            @extend .justContentCenter;
            width: 65px;
            height: 70px;
            // min-height: 62px;
            top: 15%;
            left: 0;
            border-radius: 5px 5px 41.018px 5px;
            color: var(--white);
            line-height: 1.4;

            .day {
              -webkit-text-stroke: #fff 1px;
              color: transparent;
              font-size: 1.25rem;
            }
          }

          .title {
            font-size: 1.15rem;
            margin-left: 80px;
          }
        }

        .img {
          border-radius: 10px;
          margin: 2rem 0 1rem;
        }

        .textDiv {
          @extend .flexCol;
          gap: 1rem;

          .text {
            text-align: justify;
          }

          .danger {
            @extend .flexCol;
            gap: 1rem;
          }

          .subHeader {
            font-weight: 700;
            color: var(--deep);
            margin: 1rem 0 0.5rem;
          }

          .list,
          .subList {
            padding-left: 1rem;
            @extend .flexCol;
            gap: 1rem;

            strong {
              padding-bottom: 1rem;
            }

            .listItem {
              // padding: 0.5rem 0;
              line-height: 1.5;
            }
          }

          ul li {
            list-style: disc;
            margin-left: 1.5rem
          }

          .vidText {
            @extend .flexCol;
            gap: 2rem;
            margin: 1rem 0;
          }
        }
      }

      .otherPosts {
        @extend .flexCol;
        gap: 3rem;

        .featuredPost {
          background-color: var(--deep);
          color: var(--white);
          padding: 2rem 1.25rem;
          border-radius: 10px;
          @extend .flexCol;
          gap: 1rem;

          .header {
            padding-bottom: 0.5rem;
            text-decoration: underline;
          }
          
          span {
            font-weight: 700;
            color: var(--secondary);
          }
        }

        .blogList {
          background-color: var(--light);
          padding: 2rem 1.25rem;
          border-radius: 10px;

          .header {
            padding-bottom: 0.5rem;
            text-decoration: underline;
            color: var(--deep);
          }

          .linkItem {
            border-bottom: 1px solid rgba(2, 1, 101, 0.15);

            .link {
              color: var(--black);
              text-decoration: none;
              display: block;
              padding: 1rem 0;

              &:active {
                opacity: 0.7;
              }
            }
          }
        }
      }

    }

    .mediaDiv {

      .header {
        @extend .flexAlignCol;
        padding: 2rem 0;
    
        .title {
          font-weight: 700;
          line-height: 1.4;
          text-transform: capitalize;
          text-align: center;
          margin: 1rem 0;
        }
    
        .subTitle {
          text-transform: uppercase;
          position: relative;
    
          &::before {
            content: '';
            position: absolute;
            top: 10%;
            left: -3rem;
            width: 35px;
            height: 4px;
            background: var(--secondary);
            margin: 0.5rem auto;
          }
    
          &::after {
            content: '';
            position: absolute;
            top: 10%;
            right: -3rem;
            width: 35px;
            height: 4px;
            background: var(--secondary);
            margin: 0.5rem auto;
          }
        }
    
        @media screen and (min-width: 768px) {
          .title {
            font-size: 2rem;
          }
        }
      }

      .mediaContainer {
        @extend .flexCol;
        gap: 1rem;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 5rem 5% 0;
      max-width: 1600px;
      margin: 0 auto;
      line-height: 1.5;

      .contentDiv {
        @media screen and (min-width: 1100px) {
          flex-direction: row;
        }
        .eventDetails {
          @media screen and (min-width: 1100px) {
            max-width: 60%;
            border-bottom: none;
          }
          .heading {
            .date {
              width: 75px;
              height: 80px;
              top: 0;

              .day {
                font-size: 1.35rem;
              }

              .month {
                font-size: 1rem;
              }
            }
  
            .title {
              font-size: 1.5rem;
              line-height: 1.4;
              margin-left: 95px;
            }
          }
  
          .img {
            width: 100%;
          }
  
          .textDiv {
  
            .text {
            }
  
            .vidText {

              @media screen and (min-width: 1100px) {
                flex-direction: row;
                align-items: center;

                .text {
                  width: 60%;
                }
              }
            }
          }
        }
  
        .otherPosts {
          @extend .flexCol;
          gap: 3rem;

          .blogList {
            background-color: unset;
            border-radius: 0;

            .linkItem {
              border-bottom: 1px solid var(--deep);
            }
          }
        }
  
      }
  
      .mediaDiv {
        .header {
          .title {
              font-size: 2rem;
              max-width: unset;
              span {
                display: block;
                color: var(--secondary);
            }
          }
        }
  
        .mediaContainer {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .image {
            height: 100%;
          }

          @media screen and (min-width: 1100px) {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }
    }
  }
}
