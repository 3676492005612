.topSection {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;                                                                               

  @media screen and (min-width: 768px) {
    height: 25vh;
    // width: 100vw;
  }
}

.topImage {
  position: relative;
  text-align: center;
  object-fit: cover;
  height: 150px;

  @media screen and (min-width: 768px) {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 25vh;
    top: 20vh;
    background-color: #fff;
    object-fit: cover;
    z-index: 1;
  }
}

.topHeader {
  position: absolute;
  line-height: 1.0;
  // top: 22%;
  font-size: 24px;
  z-index: 3;
  color: var(--white);
  font-weight: 900;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
    top: 17vh;
    padding: 1rem;
  }
}
