/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {

  .bg {
    position: absolute;
    min-height: 40vh;
    width: 100%;
    z-index: -1;
    left: 0;
  }
  
  .wrapper {
    padding: 3rem 1rem;

    @media screen and (min-width: 768px) {
      padding: 5rem 5%;
    }
  }

  .header {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
      max-width: 250px;
      color: white;
      margin: 1rem 0;

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        max-width: unset;
        span {
          display: block;
          color: var(--secondary);
        }
      }
    }

    .subTitle {
      text-transform: uppercase;
      position: relative;
      color: white;

      &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: -3rem;
        width: 35px;
        height: 4px;
        background: var(--secondary);
        margin: 0.5rem auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10%;
        right: -3rem;
        width: 35px;
        height: 4px;
        background: var(--secondary);
        margin: 0.5rem auto;
      }
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }
    }
  }

  .content {
    padding: 1rem 0 0;
    @extend .flexAlignCol;
    z-index: 100;

    .textContent {
      @extend .flexCol;
      gap: 1rem;

      .text {
        text-align: justify;
      }
    }

    .perksDiv {
      padding: 2rem 0;
      @extend .flexCol;
      gap: 2rem;
      z-index: 100;

      .perk {
        position: relative;
        border-radius: 10px 20px 10px 10px;
        background-color: var(--white);
        gap: 0.5rem;
        z-index: 100;
        max-width: 342px;
        min-height: 279px;
        padding: 2rem 1.5rem;
        margin: 0 auto;
        -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow:0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

        .link {
          text-decoration: underline;
          color: var(--deep);
          font-weight: 600;
          margin-top: 2rem;
          display: block;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--secondary);
          }
        }

        .iconDiv {
          position: absolute;
          background: var(--deep);
          @extend .flexAlignCol;
          @extend .justContentCenter;
          width: 69px;
          height: 54px;
          top: 0;
          left: 0;
          border-radius: 10px 5px 40px 5px;

          .number {
            margin: 0.7rem;
            -webkit-text-stroke: var(--light) 0.5px;
            color: transparent;
            font-size: 1.25rem;
          }
        }

        .div {

          .heading {
            color: var(--deep);
            text-align: right;
            margin-bottom: 0.5rem;
            font-size: 1rem;
          }

          .text {
            text-align: justify;
          }
        }
      }

      @media screen and (min-width: 768px) {
        width: 100%;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.5rem;
        padding: 2.5rem 1.5rem 2rem;
      }

      @media screen and (min-width: 1280px) {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 0.5rem;
        .perk {
          border-radius: 10px;
        }
        .div {
          .heading {
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
}
