/* Declaring some common styles */
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

/***********   */

.header {
  @extend .w100;
  position: fixed;
  top: 0;
  background-color: var(--white);
  // height: 80px;
  z-index: 100000;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .nav {
    @extend .w100;
    @extend .flex;
    @extend .relative;
    @extend .justContentSpaceBtw;
    @extend .alignItemsCenter;
    padding: 1rem;
    gap: 1rem;

    @media screen and (min-width: 768px) {
      padding: 1rem 5%;
    }

    .logo {
      .logoImg {
        width: 60%;

        @media screen and (min-width: 768px) {
          max-width: 80%;
        }
      }
    }

    .navBtns {
      .navUser {

        .btn {
          border: none;
          background: var(--menu);
          border-radius: 10px;
          font-size: 16px;
          color: var(--white);
          padding: 1rem;
          min-width: 125px;
          transition: all 0.2s ease;
      
          &:hover {
            background-color: var(--deep);
            transform: scale(1.03);
          }
      
          &:active {
            transform: scale(0.9);
          }
        }
      }
    }

    .mobileMenuIcon {
      font-size: 3rem;
      color: var(--menu);
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:active {
        color: var(--deep);
        transform: scale(0.9);
      }

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }

    .exitMenu {
      @extend .mobileMenuIcon;
      font-size: 1.8rem;
      z-index: 500000;
      @extend .absolute;
      left: 38vw;
      top: 1.2rem;
    }

    .hide {
      @extend .none;

      @media screen and (min-width: 1024px) {
        display: flex;
        opacity: 1 !important;
        transform: translateX(0) !important;
      }
    }

    .navOverlay {
      @extend .fixed;
      @extend .w100;
      height: 100vh;
      backdrop-filter: blur(2px);
      top: 0;
      left: 0;
      background: rgba(2, 1, 101, 0.15);
      opacity: 0;
    }

    .navList {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem 1rem;
      height: 70vh;
      width: 50vw;
      background: var(--white);
      z-index: 100000;

      .navItem {
        .navLink {
          color: var(--menu);
          padding: 1.25rem;
          display: block;
          width: 100%;
          background: transparent;
          border: none;
          cursor: pointer;
          text-align: left;
          transition: all 0.2s ease;

          &:hover {
            color: var(--deep);
          }
        }
      }

      @media screen and (min-width: 1024px) {
        display: flex;
        position: unset;
        height: unset;
        width: 100%;
        align-items: center;
        justify-content: center;
        background: transparent;
        gap: 2.5rem;

        .logoImg {
          display: none;
        }

        .active::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          left: 0;
          top: 130%;
          background-color: var(--deep);
        }

        .navItem {
          text-align: center;
          position: relative;

          .navLink {
            padding: 0;
            display: flex;
            gap: 1.5rem;
            background: transparent;
            justify-content: center;
            min-width: fit-content;
            border: none;
            font-weight: 500;
            font-size: 1.15rem;
            border-bottom: none;
            text-align: center;
            transition: all 0.2s ease;

            &:hover {
              // color: var(--gold);
              background: unset;
            }
          }
        }

        .active > .navLink {
          font-weight: 700;
          color: var(--deep);
        }
      }
    }

    .show {
      display: block;
    }
  }

  // @media screen and (min-width: 768px) {
  //   height: unset;
  // }

  @media screen and (min-width: 1024px) {
    .nav {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      align-items: center;
  
      .logo {
        .logoImg {
          min-width: 100px;
        }
      }

      .navOverlay {
        position: static;
        height: unset;
        width: 100%;
        background: transparent;
        margin: 0 auto;
      }
  
      .navList {
        display: flex;
        position: unset;
        padding: 0;
        width: 100%;
        height: unset;
        background: transparent;
        align-items: center;
        // justify-content: space-between;
  
        .logo {
          display: none;
        }
      }
  
      .navBtns {
        justify-self: flex-end;
        align-items: center;
        gap: 1.5rem;
  
        .navUser {
          .btn {
            border: none;
            background: var(--menu);
            border-radius: 10px;
            font-size: 20px;
            color: var(--white);
            padding: 1rem;
            min-width: 165px;
            display: flex;
            align-items: center;
            // height: 72px;
            justify-content: center;
            text-align: center;
            transition: all 0.2s ease;
        
            &:hover {
              background-color: var(--deep);
              transform: scale(1.03);
            }
        
            &:focus {
              transform: scale(0.9);
            }
          }
        }
      }
    }
  }
}
