/* Declaring some common styles */
.flex {
  display: flex;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {
  background-image: url('../../assets/background/cha_left_bg.svg');
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: left top;

  @media screen and (min-width: 1100px) {
    background-image: url('../../assets/background/charity_bg.svg');
    background-position-y: 50px;
  }
  
  .wrapper {
    padding: 3rem 1rem;

    @media screen and (min-width: 768px) {
      padding: 5rem 5%;
    }
  }

  .header {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      line-height: 1.4;
      max-width: 250px;
      text-transform: capitalize;
      text-align: center;
      margin: 1rem 0;

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        max-width: unset;
        color: var(--deep);
        span {
          display: block;
        }
      }
    }

    .subTitle {
      text-transform: uppercase;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: -3rem;
        width: 35px;
        height: 4px;
        background: var(--deep);
        margin: 0.5rem auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10%;
        right: -3rem;
        width: 35px;
        height: 4px;
        background: var(--deep);
        margin: 0.5rem auto;
      }
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }
    }
  }

  .content {
    padding: 1rem 0 0;
    @extend .flexAlignCol;

    .textContent {
      @extend .flexCol;
      gap: 1rem;

      .text {
        text-align: justify;
        padding: 0 0.5rem;
      }

      @media screen and (min-width: 768px) {
        .text {
          padding: 0;
          max-width: 650px;
          margin: 0 auto;
          text-align: center;
          line-height: 1.5;
        }
      }
    }

    .imgDesk {
      display: none;
    }

    .perksDiv {
      padding: 2rem 0;
      @extend .flexCol;
      gap: 2rem;

      .perk {
        position: relative;
        @extend .flexCol;
        border-radius: 20px 20px 0 0;
        gap: 1rem;
        max-width: 342px;
        min-height: 233px;
        margin: 0 auto;
        -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);

        .featureImg {
          border-radius: 20px 20px 0 0;
        }

        .div {
          padding: 0 1rem;
          .heading {
            color: var(--deep);
            text-transform: capitalize;
            margin-bottom: 0.5rem;
            font-size: 1rem;
          }

          .text {
            padding: 0; 
          }
        }

        .progress {
          @extend .flex;
          padding: 0 1rem;
          @extend .alignItemsCenter;
          justify-content: space-between;

          span {
            font-weight: 600;
            color: var(--deep);
          }
        }

        .action {
          @extend .flex;
          @extend .alignItemsCenter;
          justify-content: space-between;
          padding: 0 1rem 1.5rem;

          .button {
            padding: 0.6rem 1rem;
            border-radius: 8px;
            border: none;
            background: var(--deep);
            color: var(--white);
            font-weight: 600;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
              background: var(--light);
              color: var(--deep);
            }

            &:active {
             transform: scale(0.95);
            }
          }

          .trackerText {
            font-size: 0.9rem;
            color: var(--deep);
            font-weight: 600;
          }

          .trackerPercent[value] {
            height: 4px;
            border: none;
            width: 30%;
  
            &::-webkit-progress-value {
              background-color: var(--deep);
              border-radius: 2px;
              background-size: 35px 20px, 100% 100%, 100% 100%;
              -webkit-animation: animate-stripes 5s linear infinite;
              animation: animate-stripes 5s linear infinite;
            }
  
            &::-webkit-progress-bar {
              border-radius: 2px;
              border: none;
              background-color: var(--light);
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
            }
          }
  
          @-webkit-keyframes animate-stripes {
            100% { background-position: -100px 0px; }
          }
         
          @keyframes animate-stripes {
            100% { background-position: -100px 0px; }
          }
        }

        @media screen and (min-width: 1100px) {
          max-width: unset;
          min-height: unset;
        }
      }

      @media screen and (min-width: 768px) {
        width: 100%;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        padding-bottom: 0;

        .perk {
          .div {
            .text {
              text-align: justify;
              margin: 0;
              max-width: 100%;
              min-height: 175px;
            }
          }
        }

        @media screen and (min-width: 1100px) {
          max-width: 1280px;
          margin: 0 auto;
          grid-template-columns: repeat(3, 1fr);

          .perk {
            .div {
              .text {
                min-height: 180px;
              }
            }
          }
        }
      }
    }
  }
}
