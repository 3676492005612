/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {
  background-image: url('../../assets/background/test_left.png');
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: left bottom;

  @media screen and (min-width: 1100px) {
    background-image: url('../../assets/background/test_left.png'), url('../../assets/background/test_bg.png');
    background-size: 10%, 15%;
    background-repeat: no-repeat no-repeat;
    background-position: left bottom, right top;
  }
  
  .wrapper {
    padding: 3rem 1rem;

    @media screen and (min-width: 768px) {
      padding: 5rem 5%;
    }
  }

  .header {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      line-height: 1.4;
      text-transform: capitalize;
      text-align: center;
      margin: 1rem 0;

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        max-width: unset;

        span {
          display: block;
          color: var(--deep);
        }
      }
    }

    .subTitle {
      text-transform: uppercase;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: -3rem;
        width: 35px;
        height: 4px;
        background: var(--deep);
        margin: 0.5rem auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10%;
        right: -3rem;
        width: 35px;
        height: 4px;
        background: var(--deep);
        margin: 0.5rem auto;
      }

      @media screen and (min-width: 1100px) {
        color: var(--deep);

        &::before,
        &::after {
          background-color: var(--light);
        }
      }
    }

    .reviewCard {
      background: var(--deep) !important;
      color: var(--black);
      padding: 1.25rem 1rem;
      margin-top: 2rem;

      .location {
        color: var(--deep);
        font-size: 15px;;
      }

      .reviewText {
        font-size: 14px;
        text-align: center;
      }

      .reviewDetails {
        background-color: var(--deep) !important;
      }
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }

      .reviewDiv {
        width: 30%;
        .carousel {
          align-items: center;
          .reviewCard {
            max-width: 400px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 960px) {
    padding: 0% 5%;
  }
}
