.videoContainer {
  width: 100%;

  .large {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .small {
      display: none;
    }

    .large {
      display: block;
    }
  }
}
