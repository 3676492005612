/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {
  
  .wrapper {
    padding: 3rem 1rem;

    @media screen and (min-width: 768px) {
      padding: 5rem 5% 0;
    }
  }

  .header {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
      margin: 1rem 0;

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        max-width: unset;
        span {
          display: block;
          color: var(--deep);
        }
      }
    }
  }

  .content {
    padding: 1rem 0 0;
    @extend .flexAlignCol;

    .textContent {
      @extend .flexCol;
      gap: 1rem;

      .text {
        text-align: justify;
        padding: 0 0.5rem;
      }

      @media screen and (min-width: 768px) {
        gap: 2rem;
        .img {
          display: none
        }

        .text {
          text-align: left;
          padding: 0;
        }
      }
    }

    .imgDesk {
      display: none;
    }

    @media screen and (min-width: 768px) {
      align-items: flex-start;
      margin-top: 2rem;
      padding: 0;

      .imgDesk {
        display: flex;
      }

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
    }
  }
}
