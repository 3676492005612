/* Declaring some common styles*/
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}


/************/

.footer {
  background-image: url('../../assets/background/footer_bg.png');
  background-size: cover;
  padding: 50px 1.25rem 30px;
  @extend .flexAlignCol;
  color: var(--white);

  .footerTop {
    
    .subOne {
      @extend .flex;
      gap: 3rem;
      
      .logoDiv {
        @extend .flexCol;
        gap: 1rem;
        width: 55%;

        .logo {
          width: 60%;
          max-width: 150px;
        }
      }

      .socialLinkDiv {
        .title {
          color: var(--secondary);
        }

        .socialLinker {
          @extend .flex;
          margin: 1rem 0;
          align-items: center;
          gap: 1rem;

          .socialLink {
            font-size: 1.5rem;
            color: var(--light);
            transition: all 0.2s ease-in-out;

            &:hover {
              color: var(--gold);
              transform: rotate(360deg);
            }

            &:active {
              color: var(--gold);
              transform: scale(0.9);
            }
          }
        }
      }

      .links {
        display: none;
        @extend .flexCol;
        gap: 0.5rem;

        .linkOne {

          .title {
            font-weight: 600;
            font-size: 0.85rem;
            color: var(--secondary);
            margin-bottom: 1rem;
          }

            .list {
              @extend .flexCol;
              gap: 0.5rem;
              margin-top: 0.5rem;

              .elem {
                padding-bottom: 0.5rem;
                .link {
                  font-size: 0.85rem;
                  color: var(--white);
                  transition: all 0.2s ease-in-out;
  
                  &:hover {
                    color: var(--light);
                  }
                }
              }
            }
        }
      }
    }

    .location {
      @extend .flexCol;
      gap: 0.5rem;
      margin-top: 1.5rem;

      .title {
        color: var(--secondary);
      }

      .footerLink {
        @extend .flex;
        gap: 0.5rem;

        .icon {
          color: var(--light);
          font-size: 1rem;
          margin-top: 0.25rem;
        }

        .text {
          font-size: 0.85rem;
          color: var(--white);
        }
      }

      @media screen and (min-width: 768px) {
        margin: 0;
      }
    }
  }

  .footerBottom {
    width: 100%;
    margin-top: 2rem;
    text-align: center;
    border-top: 1px solid var(--light);
    padding-top: 1rem;

    .copyRight {
      max-width: 80%;
    }
  }

  @media screen and (min-width: 768px){
    padding: 5% 10% 1%;
    justify-content: space-between;
    text-align: unset;
    align-items: unset;

    .footerTop {
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .subOne {
        justify-content: space-between;
        gap: 2.5rem;
        width: 70%;

        .logoDiv {
          max-width: 250px;
        }

        .links {
          display: flex;
          flex-direction: row;
          gap: 3rem;
        }
      }
    }
  }
}
