/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {
  background: url('../../assets/background/get_inv.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: var(--white);

  @media screen and (min-width: 768px) {
    background-size: cover;
  }
  
  .wrapper {
    padding: 3rem 1rem;
    @extend .flexAlignCol;
    gap: 2rem;

    @media screen and (min-width: 768px) {
      padding: 5rem 5%;
    }
  }

  .header {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
      margin-top: 1rem;
    }

    .subTitle {
      text-transform: uppercase;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: -3rem;
        width: 35px;
        height: 4px;
        background: var(--light);
        margin: 0.5rem auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10%;
        right: -3rem;
        width: 35px;
        height: 4px;
        background: var(--light);
        margin: 0.5rem auto;
      }
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }
    }
  }

  @media screen and (min-width: 960px) {
    padding: 0% 5%;
  }
}
