/* Declaring some common styles */
.center {
  text-align: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.container {
  margin: 80px 0 0;
  @extend .relative;
  height: calc(100vh - 80px);
  @extend .flexCol;
  color: hsl(0, 0%, 100%);
  gap: 1rem;

  .hide {
    display: none;
  }

  .bgImg {
    position: absolute;
    min-width: 100%;
    height: calc(100vh - 80px);
  }

  .title {
    color: var(--secondary);
    font-size: 34px;
    font-weight: 700;
    line-height: 42px;
    max-width: 500px;
    padding: 5rem 1rem 0;
    margin-top: 15vh;
    z-index: 10;

    span {
      -webkit-text-stroke: #fff 1px;
      color: transparent;
    }
  }

  .text {
    font-size: 16px;
    max-width: 80%;
    line-height: 1.5;
    z-index: 10;
    padding: 0 1rem;
  }

  .cta {
    padding: 0 1rem;
    @extend .flex;
    gap: 1rem;
    @extend .alignItemsCenter;
    z-index: 10;
    margin-top: 1rem;

    .ctaLink {
      color: var(--white);
      @extend .relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        left: 0;
        top: 110%;
        background-color: var(--secondary);
      }
    }
  }

  .dots {
    z-index: 10;
    @extend .flex;
    gap: 1rem;
    @extend .alignItemsCenter;
    @extend .justContentCenter;
    margin-top: 3rem;

    .arrowLeft {
      border: 1px solid var(--white);
      opacity: 0.5;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      padding: 0.5rem;
      transition: all 0.2s ease;

      &:hover {
        opacity: 1;
        background-color: var(--secondary);
        border: 1px solid var(--secondary);
      }

      &:active {
        transform: scale(1.1);
      }
    }

    .arrowRight {
      @extend .arrowLeft;
    }

    .slider {
      display: none;
    }
  }

  // Container
  @media screen and (min-width: 768px) {
    height: 95vh;
    justify-content: flex-start;

    .donate {
      margin-left: 4%;
      button {
        padding: 1rem 2rem;
        font-size: 1.5rem;
      }
    }

    .bgImg {
      height: 95vh;
      object-fit: cover;
    }

    .title {
      margin-top: 18vh;
      font-size: 3.2rem;
      line-height: 1.3;
      min-width: 750px;
      max-width: 70%;
      padding: 5rem 5% 0;

      @media screen and (min-width: 1100px) {
        font-size: 72px;
        line-height: 1.1;
        margin-top: 20vh;
      }
    }

    .text {
      max-width: 700px;
      padding: 0 5% ;
      line-height: 1.6;
      font-size: 18px;

      @media screen and (min-width: 1100px) {
        max-width: 45%;
        font-size: 20px;
      }
    }

    .cta {
      margin-top: 2rem;
      gap: 2rem;
      .ctaLink {
        font-size: 18px;

        @media screen and (min-width: 1100px) {
          font-size: 20px;
        }
      }
    }

    .dots {
      margin-top: 8rem;

      @media screen and (min-width: 1100px) {
        position: absolute;
        transform: rotate(90deg);
        top: 35%;
        left: 80%;

        .slider {
          display: flex;
          align-items: center;
          gap: 1.5rem;

          .liner {
            width: 150px;
            height: 2px;
            background-color: #fff;
          }

          .enum {
            transform: rotate(180deg);
            font-size: 1.5rem;
            font-weight: 700;
            span {
              -webkit-text-stroke: 0.5px #fff;
              color: transparent;
            }
          }
        }

        .active {
          background-color: var(--secondary);
          opacity: 1;
          border: none;
        }
      }
    }
  }

  // @media screen and (min-width: 1200px) {
  //   margin-top: 7rem;

  //   .bgImg {
  //     height: 80vh;
  //     object-fit: auto;
  //   }
  // }
}
