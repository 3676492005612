@import url("http://fonts.cdnfonts.com/css/lato");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');


:root {
  /* Colour */
  --deep: rgb(2, 1, 101);
  --white: rgb(255, 255, 255);
  --light: rgb(212, 232, 247);
  --menu: #013399;
  --secondary: rgb(10, 144, 193);
  --black: #000;

  /* Fonts */
  --lato: 'lato', sans-serif;
}

/* Reset CSS */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

a,
button {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.active {
  font-weight: 700 !important;
  color: var(--deep);
  position: relative;
}

.active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  top: 130%;
  background-color: var(--deep);
}

body {
  font-family: 'montserrat', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  line-height: 23px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (min-width: 960px) {
    font-size: 18px;
    line-height: 25px;
  }
}

.reviewInfo {
  border: 1px solid var(--light);
  padding: 2rem 0 0;
  margin-bottom: 1.5rem;
  /* background: rgba(212, 232, 247, 0.13);
  box-shadow: 0px 3.5px 35px 2.625px rgba(0, 0, 0, 0.25); */
}

#root > div:nth-child(2) > section.Testimonial_container__V11nh > div > article.Testimonial_reviewDiv__groP3 > div > ul.react-multi-carousel-track > li.react-multi-carousel-item.react-multi-carousel-item--active > div > div > div {
  display: flex;
}

.reviewCard {
  /* background: var(--deep) !important; */
  color: var(--black);
  padding: 1.25rem 0;
  margin-top: 2rem;
  margin-right: 1rem;
}

/* .reviewInfo {
  
} */
.location {
  color: var(--light);
  font-size: 13px;
  font-style: italic;
  /* max-width: 90%; */
}

.reviewText {
  text-align: justify;
  padding: 1rem;
}

@media screen and (min-width: 768px) {
  .reviewText {
    min-height: 377px;
    font-size: 1rem;
    line-height: 23px;
  }
}

@media screen and (min-width: 1280px) {
  .reviewText {
    min-height: 350px;
  }
}

.reviewDetails {
  background-image: url('./assets/background/quote.png');
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: 95% 50%;
  background-color: var(--deep) !important;
  width: 90%;
  border-radius: 0 50px 0 0;
  color: white;
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  gap: 1rem;
}

.card-img-top {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
}

.react-multi-carousel-dot button {
  border: var(--shadow) !important;
}

.react-multi-carousel-dot--active button {
  background: var(--deep) !important;
}

.react-multiple-carousel__arrow {
  background: rgba(212, 232, 247, 0.5) !important;
}

.react-multiple-carousel__arrow:hover {
  background: var(--deep) !important;
}

.carousel {
  max-width: 1280px !important;
  margin: 0 auto !important;
}

/* .react-multiple-carousel__arrow--right {
  right: calc(3% + 1px);
}

.react-multiple-carousel__arrow--left {
  left: calc(3% + 1px);
} */

.html5-video-player:not(.ytp-transparent) {
  background: var(--deep) !important;
}