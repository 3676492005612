/* Declaring some common styles */
.flex {
  display: flex;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {
  background: url('../../assets/background/volunteer_bg_mob_left.svg'), url('../../assets//background/volunteer_bg_mob.svg'), var(--light);
  background-repeat: no-repeat no-repeat;
  background-size: 5%;
  background-position: left 10%, right 80%;
  position: relative;

  @media screen and (min-width: 1100px) {
    background: url('../../assets/background/volunteer_bg_mob_left.svg'), url(''), var(--white);
    background-repeat: no-repeat;
    background-size: 3%;

    .bg {
      position: absolute;
      width: 35%;
      top: 25%;
      right: 0;
      height: 150px;
      background-color: var(--light);
      border-radius: 50px 0 0 50px;
    }
  }


  
  .wrapper {
    padding: 3rem 1rem;

    @media screen and (min-width: 768px) {
      padding: 5rem 5% 0;
    }

    @media screen and (min-width: 1100px) {
      display: flex;
      padding: 5rem 0 0 5%;
      justify-content: space-between;
    }
  }

  .header {
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
      line-height: 1.4;
      text-transform: capitalize;
      max-width: 300px;
      text-align: center;
      margin: 1rem 0;

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        max-width: unset;
        span {
          display: block;
          color: var(--deep);
        }
      }
    }

    .subTitle {
      text-transform: uppercase;
      position: relative;
      color: var(--deep);

      &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: -3rem;
        width: 35px;
        height: 4px;
        background: var(--secondary);
        margin: 0.5rem auto;
      }

      &::after {
        content: '';
        position: absolute;
        top: 10%;
        right: -3rem;
        width: 35px;
        height: 4px;
        background: var(--secondary);
        margin: 0.5rem auto;
      }
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }
    }

    @media screen and (min-width: 1100px) {
      align-items: flex-start;

      .title {
        text-align: left;
      }

      .subTitle {
        left: 10%;
      }
    }
  }

  .content {
    padding: 1rem 0 0;
    @extend .flexAlignCol;

    .textContent {
      @extend .flexCol;
      gap: 1rem;

      .text {
        text-align: justify;
        padding: 0 0.5rem;
      }

      @media screen and (min-width: 1100px) {
        align-items: flex-end;
      }
    }

    .imgDesk {
      display: none;
    }

    .perksDiv {
      padding: 2rem 0;
      @extend .flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 0.5rem;
      row-gap: 1.5rem;

      .featureImg {
        height: 50px;
        object-fit: contain;
      }

      @media screen and (min-width: 768px) {
        gap: 1.5rem;
      }
    }

    @media screen and (min-width: 1100px) {

      .perksDiv {
        padding: 2rem 5%;
        width: 70%;
        align-items: flex-end;
        justify-content: unset;
        z-index: 1;
      }
    }
  }
}
